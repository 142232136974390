export const ROUTES = {
  HOME: '/',
  NOT_FOUND: '/404',
  LOGIN: '/login',
  ACCOUNT_FALLBACK: '/ws/account',
  ACCOUNT: '/ws/[wsId]/account',
  ACCOUNT_ORG_FALLBACK: '/ws/account/organization',
  ACCOUNT_ORG: '/ws/[wsId]/account/organization',
  ACCOUNT_PROFILE: '/account/profile',
  ACCOUNT_TEAM_FALLBACK: '/ws/account/team',
  ACCOUNT_TEAM: '/ws/[wsId]/account/team',
  ACCOUNT_INTEGRATIONS_FALLBACK: '/ws/account/integrations',
  ACCOUNT_INTEGRATIONS: '/ws/[wsId]/account/integrations',
  ACCOUNT_BILLING_FALLBACK: '/ws/account/billing',
  ACCOUNT_BILLING: '/ws/[wsId]/account/billing',
  ACCOUNT_USAGE_FALLBACK: '/ws/account/usage',
  ACCOUNT_USAGE: '/ws/[wsId]/account/usage',
  ACCOUNT_USERS: '/account/users',
  ACCOUNT_JOBS: '/account/jobs',
  ACCOUNT_SPARKS: '/account/sparks',
  WORKSPACE: '/ws',
  WELCOME: '/ws/[wsId]/welcome',
  DASHBOARD: '/ws/[wsId]/dashboard',
  DASHBOARD_FALLBACK: '/ws/dashboard',
  IMAGINATIONS_FALLBACK: '/ws/imaginations',
  IMAGINATIONS: '/ws/[wsId]/imaginations',
  IMAGINATION: '/ws/[wsId]/imaginations/[iId]',
  THREADS_FALLBACK: '/ws/threads',
  THREADS: '/ws/[wsId]/threads',
  THREAD: '/ws/[wsId]/threads/[tId]',
  SCRIPTS_FALLBACK: '/ws/scripts',
  SCRIPTS: '/ws/[wsId]/scripts',
  SCRIPT: '/ws/[wsId]/scripts/[sId]',
  CHARACTERS_FALLBACK: '/ws/scripts/characters',
  CHARACTERS: '/ws/[wsId]/scripts/characters',
  CHARACTER: '/ws/[wsId]/scripts/characters/[cId]',
  VOICEOVERS_FALLBACK: '/ws/scripts/voiceovers',
  VOICEOVERS: '/ws/[wsId]/scripts/voiceovers',
  VOICEOVER: '/ws/[wsId]/scripts/voiceovers/[vId]',
  BRANDS_FALLBACK: '/ws/brands',
  BRANDS: '/ws/[wsId]/brands',
  BRANDS_CREATE: '/ws/[wsId]/brands/create',
  BRAND: '/ws/[wsId]/brands/[bId]',
  PROJECTS_FALLBACK: '/ws/projects',
  PROJECTS: '/ws/[wsId]/projects',
  PROJECTS_CREATE: '/ws/[wsId]/projects/create',
  PROJECT: '/ws/[wsId]/projects/[pId]',
  COLLECTIONS_FALLBACK: '/ws/collections',
  COLLECTIONS: '/ws/[wsId]/collections',
  COLLECTION: '/ws/[wsId]/collections/[cId]',
  PLAYGROUND_FALLBACK: '/ws/playground',
  PLAYGROUND: '/ws/[wsId]/playground',
  DOCS_FALLBACK: '/ws/docs',
  DOCS: '/ws/[wsId]/docs',
  DOC: '/ws/[wsId]/docs/[dId]',
} as const;

export enum Pages {
  index = 'index',
  notFound = 'notFound',
  login = 'login',
  account = 'account',
  workspace = 'workspace',
  imaginations = 'imaginations',
  imagination = 'imagination',
  threads = 'threads',
  thread = 'thread',
  docs = 'docs',
  doc = 'doc',
  scripts = 'scripts',
  script = 'script',
  characters = 'characters',
  character = 'character',
  voiceOvers = 'voiceOvers',
  voiceOver = 'voiceOver',
  brands = 'brands',
  brand = 'brand',
  projects = 'projects',
  project = 'project',
  collections = 'collections',
  collection = 'collection',
  playground = 'playground',
  users = 'users',
  jobs = 'jobs',
  sparks = 'sparks',
}

export const isRouteMatch = (router, staticPages, dynamicPages) => {
  const isStaticRouteMatch = staticPages.includes(router.route);
  const isDynamicRouteMatch = dynamicPages.some(routePattern => {
    const regexPattern = routePattern.replace(/\[.*?\]/g, '.*?');
    const regex = new RegExp(`^${regexPattern}$`);
    return regex.test(router.route);
  });

  return isStaticRouteMatch || isDynamicRouteMatch;
};

export const replaceDynamicSegments = (routePattern, query) => {
  return routePattern.replace(/\[(\w+)\]/g, (_, key) => {
    return query[key] || '';
  });
};

export const isIntercomPage = router => {
  return isRouteMatch(router, [], [ROUTES.HOME, ROUTES.DASHBOARD, ROUTES.DOCS]);
};

export const isUserDashboardPage = router => {
  return isRouteMatch(router, [], [ROUTES.DASHBOARD]);
};

export const isDashboardPage = router => {
  return isRouteMatch(
    router,
    [],
    [
      ROUTES.WELCOME,
      ROUTES.DASHBOARD,
      ROUTES.IMAGINATIONS,
      ROUTES.IMAGINATION,
      ROUTES.THREADS,
      ROUTES.THREAD,
      ROUTES.DOCS,
      ROUTES.DOC,
      ROUTES.SCRIPTS,
      ROUTES.SCRIPT,
      ROUTES.CHARACTERS,
      ROUTES.CHARACTER,
      ROUTES.VOICEOVERS,
      ROUTES.VOICEOVER,
      ROUTES.BRANDS,
      ROUTES.BRAND,
      ROUTES.PROJECTS,
      ROUTES.PROJECT,
      ROUTES.COLLECTIONS,
      ROUTES.COLLECTION,
      ROUTES.PLAYGROUND,
    ]
  );
};

export const isAccountPage = router => {
  return isRouteMatch(
    router,
    [
      ROUTES.ACCOUNT,
      ROUTES.ACCOUNT_ORG,
      ROUTES.ACCOUNT_PROFILE,
      ROUTES.ACCOUNT_TEAM,
      ROUTES.ACCOUNT_INTEGRATIONS,
      ROUTES.ACCOUNT_BILLING,
      ROUTES.ACCOUNT_USAGE,
      ROUTES.ACCOUNT_USERS,
      ROUTES.ACCOUNT_JOBS,
      ROUTES.ACCOUNT_SPARKS,
    ],
    []
  );
};

export const isBrandsPage = router => {
  return isRouteMatch(router, [], [ROUTES.BRANDS]);
};

export const isBrandPage = router => {
  return isRouteMatch(router, [], [ROUTES.BRAND]);
};

export const isBrandCreatePage = router => {
  return isRouteMatch(router, [], [ROUTES.BRANDS_CREATE]);
};

export const isProjectsPage = router => {
  return isRouteMatch(router, [], [ROUTES.PROJECTS]);
};

export const isProjectPage = router => {
  return isRouteMatch(router, [], [ROUTES.PROJECT]);
};

export const isProjectCreatePage = router => {
  return isRouteMatch(router, [], [ROUTES.PROJECTS_CREATE]);
};

export const isCollectionsPage = router => {
  return isRouteMatch(router, [], [ROUTES.COLLECTIONS]);
};

export const isCollectionPage = router => {
  return isRouteMatch(router, [], [ROUTES.COLLECTION]);
};

export const isThreadPage = router => {
  return isRouteMatch(router, [], [ROUTES.THREADS, ROUTES.THREAD]);
};

export const isDocPage = router => {
  return isRouteMatch(router, [], [ROUTES.DOCS, ROUTES.DOC]);
};

export const isCompanyPage = router => {
  return isRouteMatch(router, [ROUTES.ACCOUNT_ORG], []);
};

export const isTeamPage = router => {
  return isRouteMatch(router, [ROUTES.ACCOUNT_TEAM], []);
};

export const isIntegrationsPage = router => {
  return isRouteMatch(router, [ROUTES.ACCOUNT_INTEGRATIONS], []);
};

export const isSubscriptionPage = router => {
  return isRouteMatch(router, [ROUTES.ACCOUNT_BILLING], []);
};

export const isUsagePage = router => {
  return isRouteMatch(router, [ROUTES.ACCOUNT_USAGE], []);
};

export const isWsPage = router => {
  return isRouteMatch(router, [], [ROUTES.WORKSPACE]);
};

export const isPlaygroundPage = router => {
  return isRouteMatch(router, [], [ROUTES.PLAYGROUND]);
};
