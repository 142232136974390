import { RectangleHorizontal, RectangleVertical, Square } from 'lucide-react';

export const modelVersions = [
  {
    key: 'stereo-melody-large',
    label: 'Echoes of Harmony',
    description:
      'Captures the essence of blending stereo sound and melody, suggesting a model that creates rich, harmonious echoes in music.',
  },
  {
    key: 'stereo-large',
    label: 'Sonic Panorama',
    description:
      "Emphasizes the model's ability to generate broad, immersive stereo soundscapes, akin to a rhapsodic exploration of audio panoramas.",
  },
  {
    key: 'melody-large',
    label: 'Lyrical Weaver',
    description:
      "Reflects the model's focus on melody, implying it weaves intricate, lyrical tapestries from musical notes.",
  },
  {
    key: 'large',
    label: 'Symphony Synthesist',
    description:
      "Conveys the model's extensive capacity to synthesize diverse musical elements into a cohesive symphony.",
  },
  // {
  //   key: 'suno',
  //   label: 'Song smith',
  //   description: '',
  // },
];

export const sizes = [
  {
    key: 'square',
    label: '1:1',
    IconComponent: Square,
  },
  {
    key: 'landscape',
    label: '3:2',
    IconComponent: RectangleHorizontal,
  },
  {
    key: 'landscape-wide',
    label: '16:9',
    IconComponent: RectangleHorizontal,
  },
  {
    key: 'portrait',
    label: '2:3',
    IconComponent: RectangleVertical,
  },
  {
    key: 'portrait-tall',
    label: '9:16',
    IconComponent: RectangleVertical,
  },
];

export const aspectSizes = {
  square: '512x512',
  landscape: '768x512',
  'landscape-wide': '1280x720',
  portrait: '512x768',
  'portrait-tall': '720x1280',
};

export const aspectSizesDallE = {
  square: '1024x1024',
  landscape: '1792x1024',
  'landscape-wide': '1792x1024',
  portrait: '1024x1792',
  'portrait-tall': '1024x1792',
};

export const aspectSizesRecraft = {
  square: '1024x1024',
  landscape: '1280x1024',
  'landscape-wide': '1707x1024',
  portrait: '1024x1280',
  'portrait-tall': '1024x1707',
};
export const aspectSizeByRatiosRecraft = {
  '1:1': '1024x1024',
  '3:2': '1280x1024',
  '16:9': '1707x1024',
  '2:3': '1024x1280',
  '9:16': '1024x1707',
};

export const aspectRatios = {
  square: '1:1',
  landscape: '3:2',
  'landscape-wide': '16:9',
  portrait: '2:3',
  'portrait-tall': '9:16',
};

export const dalleStyles = [
  {
    key: 'vivid',
    label: 'Vivid',
  },
  {
    key: 'natural',
    label: 'Natural',
  },
];

export const ideogramStyles = [
  {
    key: 'GENERAL',
    label: 'General',
  },
  {
    key: 'REALISTIC',
    label: 'Realistic',
  },
  {
    key: 'DESIGN',
    label: 'Design',
  },
  {
    key: 'RENDER_3D',
    label: 'Render 3D',
  },
  {
    key: 'ANIME',
    label: 'Anime',
  },
];

export const sdStyles = [
  {
    key: 'anime',
    label: 'Anime',
  },
];

export const sdStylesPartial = [
  {
    key: 'cinematic',
    label: 'Cinematic',
  },
];

export const sdStylesPartial1 = [
  {
    key: 'creative',
    label: 'Creative',
  },
];

export const sdStylesPartial2 = [
  {
    key: 'dynamic',
    label: 'Dynamic',
  },
  {
    key: 'environment',
    label: 'Environment',
  },
  {
    key: 'general',
    label: 'General',
  },
  {
    key: 'illustration',
    label: 'Illustration',
  },
  {
    key: 'photography',
    label: 'Photography',
  },
  {
    key: 'raytraced',
    label: 'Raytraced',
  },
  {
    key: 'render_3d',
    label: 'Render 3D',
  },
  {
    key: 'sketch_bw',
    label: 'Sketch BW',
  },
  {
    key: 'sketch_color',
    label: 'Sketch Color',
  },
];

export const sdStylesPartial3 = [
  {
    key: 'vibrant',
    label: 'Vibrant',
  },
  {
    key: 'none',
    label: 'None',
  },
];

export const sdPhotoRealStyles = [
  {
    key: 'bokeh',
    label: 'Bokeh',
  },
];

export const sdPhotoRealStylesPartial1 = [
  {
    key: 'cinematic_closeup',
    label: 'Cinematic (Closeup)',
  },
];

export const sdPhotoRealStylesParital = [
  {
    key: 'fashion',
    label: 'Fashion',
  },
  {
    key: 'film',
    label: 'Film',
  },
  {
    key: 'food',
    label: 'Food',
  },
  {
    key: 'hdr',
    label: 'HDR',
  },
  {
    key: 'long_exposure',
    label: 'Long exposure',
  },
  {
    key: 'macro',
    label: 'Macro',
  },
  {
    key: 'minimalist',
    label: 'Minimalist',
  },
  {
    key: 'monochrome',
    label: 'Monochrome',
  },
  {
    key: 'moody',
    label: 'Moody',
  },
  {
    key: 'neutral',
    label: 'Neutral',
  },
  {
    key: 'portrait',
    label: 'Portrait',
  },
  {
    key: 'retro',
    label: 'Retro',
  },
  {
    key: 'stock_photo',
    label: 'Stock photo',
  },
  {
    key: 'unprocessed',
    label: 'Unprocessed',
  },
];

export const recraftStyles = [
  {
    key: 'any',
    label: 'General',
  },
  {
    key: 'realistic_image',
    label: 'Realistic',
  },
  {
    key: 'realistic_image/b_and_w',
    label: 'B&W',
  },
  {
    key: 'realistic_image/enterprise',
    label: 'Enterprise',
  },
  {
    key: 'realistic_image/hard_flash',
    label: 'Hard Flash',
  },
  {
    key: 'realistic_image/hdr',
    label: 'HDR',
  },
  {
    key: 'realistic_image/motion_blur',
    label: 'Motion Blur',
  },
  {
    key: 'realistic_image/natural_light',
    label: 'Natural Light',
  },
  {
    key: 'realistic_image/studio_portrait',
    label: 'Studio Portrait',
  },
  {
    key: 'digital_illustration',
    label: 'Illustration',
  },
  {
    key: 'digital_illustration/pixel_art',
    label: 'Pixel Art',
  },
  {
    key: 'digital_illustration/hand_drawn',
    label: 'Hand Drawn',
  },
  {
    key: 'digital_illustration/grain',
    label: 'Grain',
  },
  {
    key: 'digital_illustration/infantile_sketch',
    label: 'Infantile Sketch',
  },
  {
    key: 'digital_illustration/2d_art_poster',
    label: '2D Art Poster',
  },
  {
    key: 'digital_illustration/handmade_3d',
    label: 'Handmade 3D',
  },
  {
    key: 'digital_illustration/hand_drawn_outline',
    label: 'Hand Drawn Outline',
  },
  {
    key: 'digital_illustration/engraving_color',
    label: 'Engraving Color',
  },
  {
    key: 'digital_illustration/2d_art_poster_2',
    label: '2D Art Poster',
  },
];
