import React, { useEffect, useMemo, useState } from 'react';

import { useCopyStore } from 'store';

import { isDesktop } from 'growl/utils/platform';
import { Button } from 'components/ui/button';

interface UnsupportedBrowserProps {
  setAccept(state: boolean): void;
}

const UnsupportedBrowserProps = ({ setAccept }: UnsupportedBrowserProps) => {
  const [isMounted, setMounted] = useState<boolean>(false);
  const isDesktopView = useMemo(() => isMounted && isDesktop(), [isMounted]);

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    setMounted(true);

    return () => {
      document.body.style.overflow = '';
    };
  }, []);

  const copies = useCopyStore(state => state.copy);

  if (!copies.global) return null;
  const {
    global: {
      errors: { unsupportedBrowser: copy },
    },
  } = copies;

  let bodyCopy: string;
  if (isDesktopView) {
    bodyCopy = copy.body.desktop;
  } else {
    bodyCopy = copy.body.mobile;
  }

  return (
    <div
      className={
        'absolute w-screen h-screen top-0 left-0 flex flex-col items-center justify-center text-center'
      }
    >
      <h2 className="scroll-m-20 border-b pb-2 text-3xl font-semibold tracking-tight first:mt-0">
        {copy.title}
      </h2>
      <br />
      <p
        className="leading-7 [&:not(:first-child)]:mt-6"
        dangerouslySetInnerHTML={{ __html: bodyCopy }}
      />
      <br />
      <Button onClick={() => setAccept(true)}>{copy.cta}</Button>
    </div>
  );
};

export default UnsupportedBrowserProps;
