import React, { useState } from 'react';
import { rgba } from 'polished';

import { Button } from 'components/ui/button';
import { colors } from 'utils/styles/theme';

const Version = () => {
  const [isOpen, setIsOpen] = useState(false);
  const onToggle = () => setIsOpen(state => !state);

  return (
    <div
      className={'fixed bottom-0 left-0'}
      style={{
        background: rgba(colors.black, 0.5),
        transition: 'right 0.5s',
      }}
    >
      {isOpen ? (
        <div className={'inline-block ml-2 text-xs invert dark:invert-0'}>
          {`(${process.env.ENV}),
            built on ${new Date(process.env.BUILD_DATE).toUTCString()}
          `}
        </div>
      ) : null}
      <Button
        variant={'transparent'}
        onClick={onToggle}
        className={'text-xs p-1 h-[unset] invert dark:invert-0'}
      >
        [ {isOpen ? '-' : '+'} ]
      </Button>
    </div>
  );
};

Version.displayName = 'Version';

export default React.memo(Version);
