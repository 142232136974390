import { ExternalToast } from 'sonner';

export const ISR_TIMEOUT = process.env.ENV === 'production' ? 300 : 10;

export const superAdmins = [
  // Sabi
  '7rRig6xHQEUBR1J9feWnqc9JvxU2',
  'kmSVowKcfPgT9vVpABoE7Lhj3fE2',
];

export const superAdminsExtended = [
  ...superAdmins,
  // Dinesh
  'zUwBxMWqg2erWj9rdxxyoklGcy53',
  'vUYkHrFImXdbFrddC7eC0JmlZLW2',
  'ujrcWNggVsTMbSKzXaQ6O3Vz7DA2',
  'zNpQY9SyvvcfMXlDvbOpfYqoSZo2',
  'OQfgVlIT73Y72G1I9t8wQXGYXhw1',
  'hUPggsc5HKMpFH9TXqaUeLnqFYp1',
  '5uuPXSZl5ZbaiARhT3n8AgN60AL2',
  'diV2OKqpoFbi6vHeefR5ABzh0193',
  // rom
  'uZ5SIkJnaXaGs5aOOkwJG0jOkUI2',
  'iZtQMvSxEqWtvBULS99XvneUrjx2',
  'bC1ROn5wvjXEsEtArxrPjwOoCcN2',
  'PbPXo6TV7rU8xZdXWKjW3neBDZ82',

  // others
  'Xx0vGZ8axrZ0Xfj3pXEuQumDXeB2',
  '5jxVzudOm5WceUn5s3ngjRqfxUy2',
  '1uTimi6CGoQlui1oko3wB6N7AoG2',
  'zNpQY9SyvvcfMXlDvbOpfYqoSZo2',
  '9OG360xIBDSyPzLKPAYUbiCV6e92',
  '6pDkcxiWUJe02cUp6iWkdj8XJv02',
  'zIKKdwaFdqR51JVLjG8wPmHrd5i1',
  'rfgpdxmwURcfFFZPd2bwvT8IiIj1',
];

export const delay = (ms: number) => {
  return new Promise(resolve => setTimeout(resolve, ms));
};

export const SUNO_API_URL = 'https://suno-api-yyrizyhnma-ez.a.run.app/';

export const ALLOWED_ORIGINS = [
  'http://localhost:3000',
  'https://preview.euryka.ai',
  'https://app.euryka.ai',
  'https://app-aags7tzujri.canva-apps.com',
  'https://app-aagnduic8p0.canva-apps.com',
  'https://app-aaguxzd82ec.canva-apps.com',
  'https://app-aagu2d-yz1e.canva-apps.com',
  'https://app-aagax0algr0.canva-apps.com',
  'https://app-aagcvrtpyug.canva-apps.com',
  'https://www.canva.com',
];

export const uidToHexColor = (uid: string) => {
  if (!uid) return '00000000';
  // Create a hash from the UID
  let hash = 0;
  for (let i = 0; i < uid.length; i++) {
    hash = uid.charCodeAt(i) + ((hash << 5) - hash);
  }

  // Convert the hash to a hex color code
  let color = '#';
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    color += ('00' + value.toString(16)).slice(-2);
  }

  return color;
};

export const TOAST_DEFAULTS: Partial<ExternalToast> = {
  position: 'top-right',
  closeButton: true,
  style: { top: 40 },
  duration: 5000,
};
