import React from 'react';

import { useCopyStore } from 'store';
import { ReactComponent as SvgBrowserDesktop } from 'svgs/browser-desktop.svg';

import ErrorTemplate from '../ErrorTemplate/ErrorTemplate';

const DesktopOnly = () => {
  const copies = useCopyStore(state => state.copy);

  if (!copies.global) return null;
  const {
    global: {
      errors: { desktopOnly: copy },
    },
  } = copies;

  return (
    <ErrorTemplate>
      <div className={'w-[64rem] h-[64rem] mx-auto'}>
        <SvgBrowserDesktop />
      </div>

      <h2 className="scroll-m-20 border-b pb-2 text-3xl font-semibold tracking-tight first:mt-0">
        {copy.title}
      </h2>
      <p
        className="leading-7 [&:not(:first-child)]:mt-6"
        dangerouslySetInnerHTML={{ __html: copy.body }}
      />
    </ErrorTemplate>
  );
};

export default DesktopOnly;
