import { AnimatePresence } from 'framer-motion';
import React, { useCallback, useState } from 'react';

import { useCopyStore } from 'store';
import { ReactComponent as SvgArrow } from 'svgs/arrow.svg';
import { ReactComponent as SvgWarning } from 'svgs/warning.svg';
import { getBannerMotionProps } from 'utils/styles/animations';
import { motion } from 'framer-motion';
import { layout } from 'utils/styles/theme';

export interface UnsupportedBannerProps {
  onContentClick: () => void;
  isVisible?: boolean;
}

const defaultProps: Partial<UnsupportedBannerProps> = {
  isVisible: false,
};

const UnsupportedBanner = ({
  onContentClick,
  isVisible,
}: UnsupportedBannerProps) => {
  const [isOpen, setOpen] = useState(true);

  const onClose = useCallback(
    (isContent = false) => {
      if (isContent) onContentClick();
      setOpen(false);
    },
    [onContentClick]
  );

  const { global } = useCopyStore(state => state.copy);
  if (!global) return null;
  const {
    errors: {
      unsupportedBrowser: {
        body: { banner: copy },
      },
    },
  } = global;

  return (
    <AnimatePresence>
      {isVisible && isOpen && (
        <motion.div
          {...getBannerMotionProps('top')}
          className={
            'fixed top-0 left-0 flex items-center justify-between w-full p-4'
          }
          style={{
            zIndex: layout.zIndex.nonFunctionals,
          }}
        >
          <div className={'flex items-center'}>
            <SvgWarning
              style={{
                height: '50rem',
                marginRight: '60rem',
              }}
            />
            <div className={'cursor-pointer'} onClick={() => onClose(true)}>
              {copy}
            </div>
          </div>
          <div className={'cursor-pointer'} onClick={() => onClose()}>
            <SvgArrow />
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

UnsupportedBanner.defaultProps = defaultProps;

export default UnsupportedBanner;
