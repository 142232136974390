export const fadeMotionProps = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  transition: { duration: 0.2 },
};

export const pageMotionProps = {
  initial: { opacity: 0, x: '50rem' },
  animate: { opacity: 1, x: '0rem' },
  exit: { opacity: 0, x: '-50rem' },
  transition: { duration: 0.2 },
};

export const getBannerMotionProps = (from: 'top' | 'bottom') => ({
  initial: { opacity: 0, y: `${(from === 'top' ? -1 : 1) * 50}%` },
  animate: { opacity: 1, y: '0%' },
  exit: { opacity: 0, y: `${(from === 'top' ? -1 : 1) * 50}%` },
  transition: { duration: 0.6 },
});
