import React from 'react';

import { SharedCopy } from 'store/copy.types';

interface JavascriptDisabled {
  sharedCopy: SharedCopy;
}

const JavascriptDisabled = ({ sharedCopy }: JavascriptDisabled) => {
  if (!sharedCopy?.global) return null;

  const {
    global: {
      errors: { javascriptDisabled: copy },
    },
  } = sharedCopy;

  return (
    <div
      className={
        'absolute w-screen h-screen top-0 left-0 flex flex-col items-center justify-center text-center fade-in-0'
      }
    >
      <h2 className="scroll-m-20 border-b pb-2 text-3xl font-semibold tracking-tight first:mt-0">
        {copy.title}
      </h2>
      <br />
      <p
        className="leading-7 [&:not(:first-child)]:mt-6"
        dangerouslySetInnerHTML={{ __html: copy.body1 }}
      />
      <br />
      <p
        className="leading-7 [&:not(:first-child)]:mt-6"
        dangerouslySetInnerHTML={{ __html: copy.body2 }}
      />
    </div>
  );
};

export default JavascriptDisabled;
