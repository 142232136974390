import {
  addDoc,
  arrayRemove,
  arrayUnion,
  collection,
  doc,
  DocumentData,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  QueryDocumentSnapshot,
  serverTimestamp,
  startAfter,
  updateDoc,
} from 'firebase/firestore';
import create from 'zustand';

import { db } from 'utils/api/firebase';

import { TODO } from './global.types';
import { Spark } from './sparks.types';
import { toast } from 'sonner';
import { TOAST_DEFAULTS } from '@/utils/config';

export interface SparksStoreType {
  initialLoading: boolean;
  setInitialLoading: (initialLoading: boolean) => void;
  sparks: Spark[];
  syncSpark: (brand: Spark) => void;

  lastVisible: QueryDocumentSnapshot<DocumentData, DocumentData>;
  setLastVisible: (
    lastVisible: QueryDocumentSnapshot<DocumentData, DocumentData>
  ) => void;

  hasMore: boolean;
  setHasMore: (hasMore: boolean) => void;

  limitCount: number;

  getSparks: (all?: boolean) => void;

  getSpark: (sparkId: string) => TODO;

  createSpark: (data: TODO, uid: string) => void;

  updateSpark: (docRef: TODO, data: TODO, uid?: string) => void;
}

const useSparksStore = create<SparksStoreType>((set, get) => ({
  initialLoading: true,
  setInitialLoading: initialLoading => {
    set(() => ({ initialLoading }));
  },

  sparks: [],
  syncSpark: brand => {
    set(({ sparks }) => {
      const sparkIndex = sparks.findIndex(b => b.id === brand.id);
      if (sparkIndex === -1) return { sparks: [...sparks, brand] };
      const updatedSparks = sparks.map((b, index) =>
        index === sparkIndex ? { ...b, ...brand } : b
      );
      return {
        sparks: updatedSparks,
      };
    });
  },
  lastVisible: null,
  setLastVisible: lastVisible => {
    set(() => ({ lastVisible }));
  },

  hasMore: true,
  setHasMore: hasMore => {
    set(() => ({ hasMore }));
  },

  limitCount: 25,

  getSparks: async (all = false) => {
    const {
      limitCount,
      lastVisible,
      syncSpark,
      hasMore,
      setHasMore,
      setLastVisible,
      getSparks,
      setInitialLoading,
    } = get();

    if (!hasMore) return;

    // init q
    let q = query(collection(db, 'sparks'));
    // add order desc and limit
    q = query(q, orderBy('createdAt', 'desc'), limit(limitCount));
    if (lastVisible) q = query(q, startAfter(lastVisible));
    const documentSnapshots = await getDocs(q);
    setInitialLoading(false);
    documentSnapshots.forEach(doc => {
      syncSpark({ id: doc.id, ref: doc.ref, ...doc.data() });
    });
    if (documentSnapshots.empty || documentSnapshots.size < limitCount)
      setHasMore(false);
    if (documentSnapshots.size) {
      setLastVisible(documentSnapshots.docs[documentSnapshots.size - 1]);
      if (all) getSparks(all);
    }
  },

  getSpark: async sparkId => {
    if (!sparkId) return;
    const { sparks, syncSpark } = get();
    const syncedBrand = sparks.find(p => p.id === sparkId);
    if (syncedBrand) return syncedBrand;
    const docRef = doc(db, 'sparks', sparkId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const brand = { id: docSnap.id, ref: docSnap.ref, ...docSnap.data() };
      syncSpark(brand);
      return brand;
    }
  },

  createSpark: async (data, uid) => {
    try {
      await addDoc(collection(db, 'sparks'), {
        ...data,
        createdBy: uid,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
      });
    } catch (error) {
      console.log('useSparksStore -- createSpark -- error:', error);
      toast.error('Something went wrong. Please try again.', TOAST_DEFAULTS);
    }
  },

  updateSpark: async (docRef, data, uid) => {
    try {
      console.log('useSparksStore -- updateSpark -- data:', data);
      await updateDoc(docRef, {
        ...data,
        updateBy: uid,
        updatedAt: serverTimestamp(),
      });
    } catch (error) {
      console.log('useSparksStore -- updateSpark -- error:', error);
      toast.error('Something went wrong. Please try again.', TOAST_DEFAULTS);
    }
  },
}));

export default useSparksStore;
