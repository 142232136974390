import { AnimatePresence } from 'framer-motion';
import React, { useEffect } from 'react';
import Balancer from 'react-wrap-balancer';

import { useCopyStore } from 'store';

import { layout } from 'utils/styles/theme';
export interface WindowTooSmallProps {
  isVisible?: boolean;
  isEnabled?: boolean;
}

const defaultProps: Partial<WindowTooSmallProps> = {
  isVisible: false,
  isEnabled: true,
};

const WindowTooSmall = ({ isVisible, isEnabled }: WindowTooSmallProps) => {
  const copies = useCopyStore(state => state.copy);

  useEffect(() => {
    // document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = '';
    };
  }, []);

  if (!copies.global) return null;
  const {
    global: {
      errors: { windowTooSmall: copy },
    },
  } = copies;

  return (
    <AnimatePresence>
      {isVisible && isEnabled && (
        <div
          className={
            'absolute w-screen h-screen top-0 left-0 flex flex-col items-center justify-center text-center'
          }
          style={{
            zIndex: layout.zIndex.nonFunctionals,
          }}
        >
          <h3>{copy.title}</h3>
          <p className={'text-balance'}>{copy.body}</p>
        </div>
      )}
    </AnimatePresence>
  );
};

WindowTooSmall.defaultProps = defaultProps;

export default WindowTooSmall;
