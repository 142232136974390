import { useRouter } from 'next/router';
import React, { useCallback } from 'react';

import { Button } from 'components/ui/button';
import { useCopyStore } from 'store';

const NotFound = () => {
  const router = useRouter();
  const copies = useCopyStore(state => state.copy);

  const onClick = useCallback(() => {
    router.back();
  }, [router]);

  if (!copies.global) return null;
  const {
    global: {
      errors: { notFound: copy },
    },
  } = copies;

  return (
    <div
      className={
        'absolute w-screen h-screen top-0 left-0 flex flex-col items-center justify-center text-center'
      }
    >
      <h2 className="scroll-m-20 pb-2 text-3xl font-semibold tracking-tight first:mt-0">
        404
      </h2>
      <p className="leading-7 [&:not(:first-child)]:mt-6">{copy.body}</p>
      <br />
      <Button onClick={onClick} variant={'link'}>
        Back
      </Button>
    </div>
  );
};

export default NotFound;
