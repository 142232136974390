import {
  addDoc,
  arrayRemove,
  arrayUnion,
  collection,
  doc,
  DocumentData,
  getDoc,
  getDocs,
  limit,
  or,
  orderBy,
  query,
  QueryDocumentSnapshot,
  serverTimestamp,
  setDoc,
  startAfter,
  updateDoc,
  where,
} from 'firebase/firestore';
import create from 'zustand';

import { db, updateActivity } from 'utils/api/firebase';

import { TODO } from './global.types';
import { Document } from './documents.types';
import _ from 'lodash';

const IS_DEBUG = process.env.IS_DEBUG && false;

export interface DocumentsStoreType {
  initialLoading: boolean;
  setInitialLoading: (initialLoading: boolean) => void;

  documents: Document[];
  syncDocument: (document: Document) => void;

  lastVisible: QueryDocumentSnapshot<DocumentData, DocumentData>;
  setLastVisible: (
    lastVisible: QueryDocumentSnapshot<DocumentData, DocumentData>
  ) => void;

  hasMore: boolean;
  setHasMore: (hasMore: boolean) => void;

  limitCount: number;

  getDocuments: (
    wsId: string,
    uid: string,
    uemail: string,
    all?: boolean
  ) => void;

  getDocument: (wsId: string, dId: string) => TODO;

  createDocument: (wsId: string, uid: string, data: TODO, dId?: string) => void;

  updateDocument: (docRef: TODO, data: TODO, uid?: string) => void;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useDocumentsStore = create<DocumentsStoreType>((set, get) => ({
  initialLoading: true,
  setInitialLoading: initialLoading => {
    set(() => ({ initialLoading }));
  },

  documents: [],
  syncDocument: document => {
    set(({ documents }) => {
      const documentIndex = documents.findIndex(t => t.id === document.id);
      if (documentIndex === -1) return { documents: [...documents, document] };
      const updatedDocuments = documents.map((t, index) =>
        index === documentIndex ? document : t
      );
      return {
        documents: updatedDocuments.sort((a, b) => b.createdAt - a.createdAt),
      };
    });
  },
  hasMore: true,
  setHasMore: hasMore => {
    set(() => ({ hasMore }));
  },
  limitCount: 13,

  lastVisible: null,
  setLastVisible: lastVisible => {
    set(() => ({ lastVisible }));
  },

  getDocuments: async (wsId, uid, uemail, all = false) => {
    if (!wsId || !uid || !uemail) return;
    if (IS_DEBUG)
      console.log(
        'useDocumentsStore -- getDocuments -- wsId:',
        wsId,
        'uid:',
        uid,
        'uemail:',
        uemail
      );
    const {
      limitCount,
      lastVisible,
      syncDocument,
      hasMore,
      setHasMore,
      setLastVisible,
      getDocuments,
      setInitialLoading,
    } = get();

    if (!hasMore) return;

    // init q
    let q = query(
      collection(db, 'users', wsId, 'documents'),
      or(
        where('createdBy', '==', uid),
        where('pinned', 'array-contains', uid),
        where('readers', 'array-contains', uemail),
        where('editors', 'array-contains', uemail)
      )
    );
    // add order desc and limit
    q = query(
      q,
      orderBy('pinned', 'desc'),
      orderBy('createdAt', 'desc'),
      limit(limitCount)
    );

    // has no more starred
    if (lastVisible) {
      q = query(q, startAfter(lastVisible));
    }
    const documentSnapshots = await getDocs(q);
    setInitialLoading(false);
    if (IS_DEBUG)
      console.log(
        'useDocumentsStore -- getDocuments -- documentSnapshots:',
        documentSnapshots.size
      );
    documentSnapshots.forEach(doc => {
      syncDocument({ id: doc.id, ref: doc.ref, ...doc.data() });
    });
    if (documentSnapshots.empty || documentSnapshots.size < limitCount)
      setHasMore(false);

    if (documentSnapshots.size) {
      setLastVisible(documentSnapshots.docs[documentSnapshots.size - 1]);
      if (all) getDocuments(wsId, uid, uemail, all);
    }
  },

  getDocument: async (wsId, dId) => {
    if (!wsId || !dId) return;
    const { documents, syncDocument } = get();
    const syncedDocument = documents.find(t => t.id === dId);
    if (syncedDocument) return syncedDocument;
    const docRef = doc(db, 'users', wsId, 'documents', dId);
    const docSnap = await getDoc(docRef);
    const document = docSnap.exists()
      ? { id: docSnap.id, ref: docSnap.ref, ...docSnap.data() }
      : null;
    if (document) syncDocument(document);
    return document;
  },

  createDocument: async (wsId, uid, data, dId) => {
    try {
      if (!wsId) throw new Error('Workspace ID is required.');
      if (dId) {
        await setDoc(doc(db, 'users', wsId, 'documents', dId), {
          ...data,
          hasPendingWrites: true,
          pinned: [],
          readers: [],
          editors: [],
          createdBy: uid,
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp(),
        });
        updateActivity({ wsId, uid, field: 'documents' });
        return;
      }
      await addDoc(collection(db, 'users', wsId, 'documents'), {
        ...data,
        hasPendingWrites: true,
        pinned: [],
        readers: [],
        editors: [],
        createdBy: uid,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
      });
      updateActivity({ wsId, uid, field: 'documents' });
    } catch (error) {
      if (IS_DEBUG)
        console.log('useDocumentsStore -- createDocument -- error:', error);
    }
  },

  updateDocument: async (docRef, data, uid) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { starred, pinned, ...restProps } = data;
    try {
      if (IS_DEBUG)
        console.log(
          'useDocumentsStore -- updateDocument -- docRef.path:',
          docRef.path,
          'data:',
          data,
          'uid:',
          uid
        );

      await updateDoc(docRef, {
        ...restProps,
        ...((starred || pinned) && {
          ...(starred !== undefined && uid
            ? {
                starred:
                  starred === 'remove' ? arrayRemove(uid) : arrayUnion(uid),
              }
            : {}),
          ...(pinned !== undefined && uid
            ? {
                pinned:
                  pinned === 'remove' ? arrayRemove(uid) : arrayUnion(uid),
              }
            : {}),
        }),
        ...(uid && { updatedBy: uid }),
        updatedAt: serverTimestamp(),
      });
    } catch (error) {
      if (IS_DEBUG)
        console.log('useDocumentsStore -- updateDocument -- error:', error);
    }
  },
}));

export default useDocumentsStore;
