import React, { ReactNode } from 'react';

import logoPng from 'assets/images/logo.png';

import { layout } from 'utils/styles/theme';

interface ErrorTemplate {
  children: ReactNode;
}

const ErrorTemplate = ({ children }: ErrorTemplate) => {
  return (
    <div
      className={'fixed top-0 left-0 w-screen h-screen grid'}
      style={{
        gridTemplateRows: 'max-content 1fr',
      }}
    >
      <div
        className={'w-full'}
        style={{
          height: layout.header.h,
        }}
      >
        <picture>
          <img
            src={logoPng}
            alt=""
            role="presentation"
            width="100"
            height="40"
            loading="lazy"
            className={'w-[100rem] h-auto'}
          />
        </picture>
      </div>

      <div className={'grid place-items-center p-4'}>
        <div
          className={
            'grid gap-2 w-full max-w-[430rem] px-[50rem] py-[40rem] border-r-2 text-center'
          }
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default ErrorTemplate;
